/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Menu from "./menu"
import Footer from "./footer"
import "./layout.css"

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          author,
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} subTitle={props.subTitle}/>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1024,
          paddingTop: 0,
        }}
      >
        <Menu></Menu>
          <div style={{marginLeft: "10px"}}>
            <hr/>
            <main>{props.children}</main>
            <hr/>
          </div>
                  <Footer author={data.site.siteMetadata.author}/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
