import React from 'react'
import Link from 'gatsby-link';

function  Menu() {
  const wrapper = {
    color: "#777",
    textDecoration: "none",
    }
  return (
    <div style={{
      background: "#ffffff",
      paddingTop: "0px",
      fontWeight: "bold",
      marginTop: "1rem",
    }}>
      <ul style={{
        listStyle: "none",
        display: "flex",
        justifyContent: "space-evenly",
      }}>
          <li><Link style={wrapper} to="/">home</Link></li>
          <li><Link style={wrapper}to="/about">about</Link></li>
          <li><Link style={wrapper}to="/projects">projects</Link></li>
          <li><Link style={wrapper} to="/interests">interests</Link></li>
      </ul>

    </div>
  )
}

export default Menu
