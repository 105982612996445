import React from 'react'

function Footer(props) {
  return (
    <div>
      <footer padding="1rem 1rem 1rem 1rem" align="right" style={{fontSize: "75%"}}>
        © {new Date().getFullYear()} {props.author}, 
        Powered by <a href="https://www.reactjs.org">Reactjs</a>
        {' '} & {' '}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </footer>
    </div>
  )
}

export default Footer
