import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.css"
import Typography from 'typography'
import judahTheme from 'typography-theme-judah'

// const Header = ({ siteTitle }) => (
export default function Header(props) {
  const typography = new Typography(judahTheme)
  return (
    <header>
      {typography.injectStyles()}
      <div className="overlay">
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,}}
        >
        <h1>{props.siteTitle}<br/> <span style={{
          fontSize: "60%",
          lineHeight: "70%"
        }}>{props.subTitle}</span></h1>
        </Link>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

// export default Header
